// import React from "react";
// import "./product.css";
// import p6image from "../Images/p6.jpg";
// import p9image from "../Images/p9.jpg";
// import p10image from "../Images/p10.jpg";
// import p11image from "../Images/p11.jpg";
// import p12image from "../Images/p12.jpeg";
// const Product = () => {
//   return (
//     <div className="main-container">
//       <section>
//         <div className="product">
//           <h1>PRODUCT</h1>
//           <h4>
//             <span style={{ color: "Yellow" }}>Home/</span>PRODUCT
//           </h4>
//         </div>
//       </section>
//       {/* <div style={{ textAlign: "justify", width: "100%" }}>
//         Product Range: Sudarshana Farms offers a diverse range of fresh fruits
//         and vegetables, meticulously selected from the best growing regions. Our
//         product portfolio includes:
//       </div> */}
//       <div class="col-lg-12 col-md-12">
//             <div class="contactinfo">
// <p> Product Range: Sudarshana Farms offers a diverse range of fresh fruits
//         and vegetables, meticulously selected from the best growing regions. Our
//         product portfolio includes:</p>
//                 </div>
//             </div>
    
//       <section className="product-card">
//         <div className="row row-cols-1 row-cols-md-2 g-4 product-col cardpro">
//           <div className="col" style={{ height: "600px" }}>
//             <div className="card">
//               <img src={p6image} className="card-img-top" alt="Grapes" />
//               <div className="card-body">
//                 <h5 className="card-title" style={{ textAlign: "center" }}>
//                   Grapes(Fresh Seedless)
//                 </h5>
//                 <p style={{ textAlign: "justify" }}>
//                   &#8226;Thompson(Seedless): Known for their sweet flavour and
//                   crisp texture, Thompson seedless grapes are a favourite among
//                   consumers.
//                   <br />
                  
//                   &#8226;Crimson(Seedless):These seedless grapes are prized
//                   for their vibrant red color and excellent shelf life, making
//                   them ideal for export.
//                 </p>
//               </div>
//             </div>
//           </div>
//           <div className="col" style={{ height: "600px" }}>
//             <div className="card">
//               <img
//                 src={p12image}
//                 className="card-img-top"
//                 alt="Onion"
//                 style={{ height: "220px" }}
//               />
//               <div className="card-body">
//                 <h5 className="card-title" style={{ textAlign: "center" }}>
//                   Red Onion
//                 </h5>
//                 <p style={{ textAlign: "justify" }}>
//                   {" "}
//                   &#8226; Our red onions are selected for their deep colour,
//                   crisp texture, and pungent flavour, essential for a wide range
//                   of culinary uses. <br />
//                   &#8226; Nashik is famous for its onions, and it is also the
//                   largest onion supplier in India. Accounting for almost 40% of
//                   the total crop.
//                 </p>
//               </div>
//             </div>
//           </div>
//           <div className="col" style={{ height: "600px" }}>
//             <div className="card">
//               <img src={p9image} className="card-img-top" alt="Banana" />
//               <div className="card-body">
//                 <h5 className="card-title" style={{ textAlign: "center" }}>
//                   Banana
//                 </h5>
//                 <p style={{ textAlign: "justify" }}>
//                   {" "}
//                   &#8226; Basrai: This variety is renowned for its rich flavour,
//                   smooth texture, and high nutritional value. The Basrai banana
//                   is a staple fruit in many households worldwide.
//                 </p>
//               </div>
//             </div>
//           </div>
//           <div className="col" style={{ height: "600px" }}>
//             <div className="card">
//               <img src={p10image} className="card-img-top" alt="Green Chilli" />
//               <div className="card-body">
//                 <h5 className="card-title" style={{ textAlign: "center" }}>
//                   Green Chilli
//                 </h5>
//                 <p style={{ textAlign: "justify" }}>
//                   {" "}
//                   &#8226; G4: The G4 variety of green chilli is celebrated for
//                   its moderate heat and robust flavour, perfect for adding a
//                   spicy kick to culinary dishes.
//                 </p>
//               </div>
//             </div>
//           </div>
//         </div>
//       </section>
//     </div>
//   );
// };

// export default Product;
import React, { useState } from "react";
import "./product.css";
import p6image from "../Images/p6.jpg";
// import p6image2 from "../Images/p6.jpg";
// import p6image3 from "../Images/p6.jpg";
import p9image from "../Images/p9.jpg";
import p10image from "../Images/p10.jpg";
// import p11image from "../Images/";
import p12image from "../Images/p12.jpeg";
import p11image from "../Images/ThompsonSeedless.jpg"
import p13image from "../Images/SharadBlackSeedless.jpg"
import p14image from "../Images/Crimson Seedless.jpg"

const Product = () => {
  const [showMoreGrapes, setShowMoreGrapes] = useState(false);

  const handleAddMoreGrapesClick = () => {
    setShowMoreGrapes(!showMoreGrapes);
  };

  return (
    <div className="main-container">
      <section>
        <div className="product">
          <h1>PRODUCT</h1>
          <h4>
            <span style={{ color: "Yellow" }}>Home/</span>PRODUCT
          </h4>
        </div>
      </section>

      <div className="col-lg-12 col-md-12">
        <div className="contactinfo">
          <p>
            Product Range: Sudarshana Farms offers a diverse range of fresh
            fruits and vegetables, meticulously selected from the best growing
            regions. Our product portfolio includes:
          </p>
        </div>
      </div>

      <section className={`product-card ${showMoreGrapes ? "show-more" : ""}`}>
        
        <div className="row row-cols-1 row-cols-md-2 g-4 product-col cardpro">
          {/* Main grape card */}
          <div className="col">
            <div className="card">
              <img src={p6image} className="card-img-top" alt="Grapes" />
              <div className="card-body">
              
                <h5 className="card-title" style={{ textAlign: "center" }}>
                Fresh Seedless Grapes
                </h5>
                <p style={{ textAlign: "justify" }}>
                  &#8226; Fresh seedless grapes from Nashik, India, are renowned for their high quality, crisp texture, and naturally sweet flavour.
                   Grown in the ideal climatic conditions of the region, these grapes are a popular choice for export due to their extended shelf life and consistent size.
                  {/* <br />
                  &#8226;Crimson (Seedless): These seedless grapes are prized for
                  their vibrant red color and excellent shelf life, making them
                  ideal for export. */}
                </p>
                <br />
                
                <div className="availability-row">
        <div className="month-status">Jan: <span className="status available">A</span></div>
        <div className="month-status">Feb: <span className="status available">A</span></div>
        <div className="month-status">Mar: <span className="status available">A</span></div>
        <div className="month-status">Apr: <span className="status unavailable">NA</span></div>
        <div className="month-status">May: <span className="status unavailable">NA</span></div>
        <div className="month-status">Jun: <span className="status unavailable">NA</span></div>
        <div className="month-status">Jul: <span className="status unavailable">NA</span></div>
        <div className="month-status">Aug: <span className="status unavailable">NA</span></div>
        <div className="month-status">Sep: <span className="status unavailable">NA</span></div>
        <div className="month-status">Oct: <span className="status unavailable">NA</span></div>
        <div className="month-status">Nov: <span className="status unavailable">NA</span></div>
        <div className="month-status">Dec: <span className="status available">A</span></div>
      </div>
      {/* <br /> */}
      <br />
                <button
                  onClick={handleAddMoreGrapesClick}
                  className="add-more-button"
                >
                  {showMoreGrapes ? "Show Less" : "Show More"}
                </button>
              </div>
            </div>
          </div>
          
          {/* Other product cards */}
          
          {showMoreGrapes && (
          <div className="row row-cols-1 row-cols-md-2 g-4 product-col cardpro more-grapes">
            {/* Additional grape types */}
            <div className="col more-grapes-item">
              <div className="card">
                <img src={p11image} className="card-img-top" alt="Autumn Royal" />
                <div className="card-body">
                  <h5 className="card-title" style={{ textAlign: "center" }}>
                  Thompson Seedless (White Seedless)
                  </h5>
                  <p style={{ textAlign: "justify" }}>
                    &#8226; Ever green variety of India, with longer shelf life. 
                    This variety comes with medium to big size, round shaped, green berries.
                     Its crispy and crunchy feature brings demand across the world.
                  <br />
                  <div>&#8226; Colour-Green to milky Green </div>
                  <div>&#8226; Brix-16+ </div>
                  <div>&#8226; Size-16+mm</div>
                    
                  <div className="availability-row">
        <div className="month-status">Jan: <span className="status available">A</span></div>
        <div className="month-status">Feb: <span className="status available">A</span></div>
        <div className="month-status">Mar: <span className="status available">A</span></div>
        <div className="month-status">Apr: <span className="status unavailable">NA</span></div>
        <div className="month-status">May: <span className="status unavailable">NA</span></div>
        <div className="month-status">Jun: <span className="status unavailable">NA</span></div>
        <div className="month-status">Jul: <span className="status unavailable">NA</span></div>
        <div className="month-status">Aug: <span className="status unavailable">NA</span></div>
        <div className="month-status">Sep: <span className="status unavailable">NA</span></div>
        <div className="month-status">Oct: <span className="status unavailable">NA</span></div>
        <div className="month-status">Nov: <span className="status unavailable">NA</span></div>
        <div className="month-status">Dec: <span className="status unavailable">NA</span></div>
      </div>
                  </p>
                </div>
              </div>
            </div>
            <div className="col more-grapes-item">
              <div className="card">
                <img src={p13image} className="card-img-top" alt="Flame Seedless" />
                <div className="card-body">
                  <h5 className="card-title" style={{ textAlign: "center" }}>
                    Sharad Black (Seedless)
                  </h5>
                  <p style={{ textAlign: "justify" }}>
                    &#8226; Sharad is another popular variety with longer shelf life.
                     It has round shaped berries with white cluster on it. Colour of this is slightly brownish to back.
                    <br />
                    <div>&#8226; Color-Black</div>
                    <div>&#8226; Brix-18+</div>
                    <div>&#8226; Size-18+mm</div>
                    <div className="availability-row">
        <div className="month-status">Jan: <span className="status available">A</span></div>
        <div className="month-status">Feb: <span className="status available">A</span></div>
        <div className="month-status">Mar: <span className="status available">A</span></div>
        <div className="month-status">Apr: <span className="status unavailable">NA</span></div>
        <div className="month-status">May: <span className="status unavailable">NA</span></div>
        <div className="month-status">Jun: <span className="status unavailable">NA</span></div>
        <div className="month-status">Jul: <span className="status unavailable">NA</span></div>
        <div className="month-status">Aug: <span className="status unavailable">NA</span></div>
        <div className="month-status">Sep: <span className="status unavailable">NA</span></div>
        <div className="month-status">Oct: <span className="status unavailable">NA</span></div>
        <div className="month-status">Nov: <span className="status unavailable">NA</span></div>
        <div className="month-status">Dec: <span className="status unavailable">NA</span></div>
      </div>
                  </p>
                </div>
              </div>
            </div>
            
            <div className="col more-grapes-item">
              <div className="card">
                <img src={p14image} className="card-img-top" alt="Flame Seedless" style={{Color:"black"}} />
                <div className="card-body">
                  <h5 className="card-title" style={{ textAlign: "center" }}>
                  Crimson Seedless (Red Seedless)
                  </h5>
                  <p style={{ textAlign: "justify" }}>
                    &#8226; Crimson is newly cultivated variety in India, very popular with longest shelf life which helps to ship with transit time around 35-40 days. 
                    Because of its big size and brix level we have seen the high demand, customer keep demanding repeatedly.

                    <br />
                    <div>&#8226; Colour-Red </div>
                    <div>&#8226; Brix-16+  </div>
                    <div>&#8226; Size-16+mm</div>
                    
                    <div className="availability-row">
        <div className="month-status">Jan: <span className="status available">A</span></div>
        <div className="month-status">Feb: <span className="status available">A</span></div>
        <div className="month-status">Mar: <span className="status available">A</span></div>
        <div className="month-status">Apr: <span className="status unavailable">NA</span></div>
        <div className="month-status">May: <span className="status unavailable">NA</span></div>
        <div className="month-status">Jun: <span className="status unavailable">NA</span></div>
        <div className="month-status">Jul: <span className="status unavailable">NA</span></div>
        <div className="month-status">Aug: <span className="status unavailable">NA</span></div>
        <div className="month-status">Sep: <span className="status unavailable">NA</span></div>
        <div className="month-status">Oct: <span className="status unavailable">NA</span></div>
        <div className="month-status">Nov: <span className="status unavailable">NA</span></div>
        <div className="month-status">Dec: <span className="status unavailable">NA</span></div>
      </div>
                     
                  </p>
                </div>
              </div>
            </div>
          </div>
        )}
        <div className="col">
            <div className="card">
              <img src={p12image} className="card-img-top" alt="Onion" />
              <div className="card-body">
                <h5 className="card-title" style={{ textAlign: "center" }}>
                  Red Onion
                </h5>
                <p style={{ textAlign: "justify" }}>
                  &#8226; Our red onions are selected for their deep colour,
                  crisp texture, and pungent flavour, essential for a wide range
                  of culinary uses. <br />
                  &#8226; Nashik is famous for its onions, and it is also the
                  largest onion supplier in India. Accounting for almost 40% of
                  the total crop. <br />
                  &#8226; Origin:- India <br />
                  &#8226; Size:- 55mm+ <br />
                  &#8226; Packing:- 10kg,20kg,25kg,50kg
                  <div></div>
                  <div></div>
                </p>
                <div className="availability-row">
        <div className="month-status">Jan: <span className="status available">A</span></div>
        <div className="month-status">Feb: <span className="status available">A</span></div>
        <div className="month-status">Mar: <span className="status available">A</span></div>
        <div className="month-status">Apr: <span className="status available">A</span></div>
        <div className="month-status">May: <span className="status available">A</span></div>
        <div className="month-status">Jun: <span className="status available">A</span></div>
        <div className="month-status">Jul: <span className="status available">A</span></div>
        <div className="month-status">Aug: <span className="status unavailable">NA</span></div>
        <div className="month-status">Sep: <span className="status unavailable">NA</span></div>
        <div className="month-status">Oct: <span className="status unavailable">NA</span></div>
        <div className="month-status">Nov: <span className="status unavailable">NA</span></div>
        <div className="month-status">Dec: <span className="status available">A</span></div>
      </div>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="card">
              <img src={p9image} className="card-img-top" alt="Banana" />
              <div className="card-body">
                <h5 className="card-title" style={{ textAlign: "center" }}>
                  Banana
                </h5>
                <p style={{ textAlign: "justify" }}>
                  &#8226; Basrai: This variety is renowned for its rich flavour,
                  smooth texture, and high nutritional value. The Basrai banana
                  is a staple fruit in many households worldwide. <br />
                  &#8226; Origin:- India <br />
                  &#8226; Packing:-13kg
                  <br />
                  &#8226; Hands:-4,5,6,7
                </p>
                <div className="availability-row">
        <div className="month-status">Jan: <span className="status available">A</span></div>
        <div className="month-status">Feb: <span className="status available">A</span></div>
        <div className="month-status">Mar: <span className="status available">A</span></div>
        <div className="month-status">Apr: <span className="status unavailable">NA</span></div>
        <div className="month-status">May: <span className="status unavailable">NA</span></div>
        <div className="month-status">Jun: <span className="status unavailable">NA</span></div>
        <div className="month-status">Jul: <span className="status unavailable">NA</span></div>
        <div className="month-status">Aug: <span className="status unavailable">NA</span></div>
        <div className="month-status">Sep: <span className="status available">A</span></div>
        <div className="month-status">Oct: <span className="status available">A</span></div>
        <div className="month-status">Nov: <span className="status available">A</span></div>
        <div className="month-status">Dec: <span className="status available">A</span></div>
      </div>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="card">
              <img src={p10image} className="card-img-top" alt="Green Chilli" />
              <div className="card-body">
                <h5 className="card-title" style={{ textAlign: "center" }}>
                  Green Chilli
                </h5>
                <p style={{ textAlign: "justify" }}>
                  &#8226; G4: The G4 variety of green chilli is celebrated for its
                  moderate heat and robust flavour, perfect for adding a spicy
                  kick to culinary dishes.
                </p>
                <br />
                <br />
                <br />
                <div className="availability-row">
        <div className="month-status">Jan: <span className="status available">A</span></div>
        <div className="month-status">Feb: <span className="status available">A</span></div>
        <div className="month-status">Mar: <span className="status available">A</span></div>
        <div className="month-status">Apr: <span className="status available">A</span></div>
        <div className="month-status">May: <span className="status available">A</span></div>
        <div className="month-status">Jun: <span className="status available">A</span></div>
        <div className="month-status">Jul: <span className="status available">A</span></div>
        <div className="month-status">Aug: <span className="status available">A</span></div>
        <div className="month-status">Sep: <span className="status available">A</span></div>
        <div className="month-status">Oct: <span className="status available">A</span></div>
        <div className="month-status">Nov: <span className="status available">A</span></div>
        <div className="month-status">Dec: <span className="status available">A</span></div>
      </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Product;

