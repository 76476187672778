import React from "react";
import "./Content.css";
// import { GiPlantRoots } from "react-icons/gi";
// import { GiReceiveMoney } from "react-icons/gi";
// import { GiTreeBranch } from "react-icons/gi";
// import { IoDiamond } from "react-icons/io5";
import { TbTargetArrow } from "react-icons/tb";
import { TbBulb } from "react-icons/tb";

import { FaHandHoldingHeart } from "react-icons/fa";

import p1 from "../Images/P1.png"
import p2 from "../Images/p2.jpg"
import p3 from "../Images/p3.png"
import p6 from "../Images/p6.jpg"
const Content = () => {
  return (
    <div className="content main-container">
      <div id="carouselExampleCaptions" class="carousel slide carouselslide">
        <div class="carousel-indicators">
          <button
            type="button"
            data-bs-target="#carouselExampleCaptions"
            data-bs-slide-to="0"
            class="active"
            aria-current="true"
            aria-label="Slide 1"
          ></button>
          <button
            type="button"
            data-bs-target="#carouselExampleCaptions"
            data-bs-slide-to="1"
            aria-label="Slide 2"
          ></button>
          <button
            type="button"
            data-bs-target="#carouselExampleCaptions"
            data-bs-slide-to="2"
            aria-label="Slide 3"
          ></button>
        </div>
        <div class="carousel-inner">
          <div class="carousel-item active">
            
            <img src={p1} alt="Your Image Description" style={{height:"800px"}} className="carouselimg img-fluid img-responsive"/>

            <div class="carousel-caption d-none d-md-block">
              <h5>Sudarshana Farms</h5>
            
            </div>
          </div>
          <div class="carousel-item">
           
            <img src={p6} alt="Your Image Description" style={{height:"1000px"}} className="carouselimg img-fluid img-responsive"/>
            <div class="carousel-caption d-none d-md-block">
              <h5>Sudarshana Farms</h5>
              
            </div>
          </div>
          <div class="carousel-item">
           
            <img src={p3} alt="Your Image Description" style={{height:"800px"}} className="carouselimg img-fluid img-responsive"/>
            <div class="carousel-caption d-none d-md-block">
              <h5>Sudarshana Farms</h5>
             
            </div>
          </div>
        </div>
        <button
          class="carousel-control-prev"
          type="button"
          data-bs-target="#carouselExampleCaptions"
          data-bs-slide="prev"
        >
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Previous</span>
        </button>
        <button
          class="carousel-control-next"
          type="button"
          data-bs-target="#carouselExampleCaptions"
          data-bs-slide="next"
        >
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Next</span>
        </button>
      </div>
      <section>
        <div class="card-group">
          <div class="card">
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100px",
              }}
            >
              {/* <GiPlantRoots
                style={{ height: "90%", width: "auto", color: "green" }}
              /> */}
              <TbBulb  style={{ height: "90%", width: "auto", color: "green" }}/>
            </div>

            <div class="card-body">
              <h5 class="card-title" style={{textAlign:"center"}}>Vision</h5>
              <p class="card-text">
              To become a global leader in the export of fresh fruits and vegetables, recognized for our commitment to quality, reliability, and ethical business practices              </p>
            </div>
          </div>
          <div class="card">
           
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100px",
              }}
            >
              {/* <GiReceiveMoney
                style={{ height: "90%", width: "auto", color: "green" }}
              /> */}
              <TbTargetArrow   style={{ height: "90%", width: "auto", color: "green" }}/>
            </div>
            <div class="card-body">
              <h5 class="card-title" style={{textAlign:"center"}}>Mission</h5>
              <p class="card-text">
              Sudarshana Farms is dedicated to providing our customers with the freshest and highest quality fruits and vegetables, sourced from trusted growers and delivered with exceptional service. We aim to foster long-term partnerships with both our suppliers and customers, ensuring mutual success and sustainability.              </p>
            </div>
          </div>
          <div class="card">
            {/* <img src="..." class="card-img-top" alt="..." /> */}
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100px",
              }}
            >
             
              <FaHandHoldingHeart style={{ height: "90%", width: "auto", color: "green" }}/>
            </div>
            <div class="card-body">
              <h5 class="card-title" style={{textAlign:"center"}}>Core Values:</h5>
              <p class="card-text">
              1.Quality: We are committed to delivering only the finest quality produce to our customers, adhering to stringent quality standards at every stage of the supply chain.
              <br />
              <br />
              2.Customer Focus: We prioritize customer satisfaction, understanding their needs and preferences to deliver tailored solutions and exceptional service
<br /><br />
3.Integrity: We conduct our business with honesty, transparency, and fairness, building trust with our stakeholders through ethical practices and accountability.
                            </p>
            </div>
          </div>
        </div>
      </section>
      
    </div>
  );
};

export default Content;
