import React from "react";
import "./Footer.css";
import { Link } from "react-router-dom";
import logoImage from "../Images/logo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faInstagram,
  faFacebook,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons";
const Footer = () => {
  return (
    
    <footer class="footer-section main-container">
      <div class="container">
        <div class="footer-content pt-5 pb-5">
          <div class="row">
            <div class="col-xl-4 col-lg-4 mb-50">
              <div class="footer-widget">
                <div class="footer-logo">
                  <a href="index.html">
                    <img src={logoImage} class="img-fluid" alt="logo" />
                  </a>
                </div>
                <div class="footer-text" style={{textAlign:"justify",width:"100%"}}>
                 <p>
                 Sudarshana Farms is a dynamic and forward-thinking company specializing in the export of fresh fruits and vegetables. Our mission is to deliver premium quality produce to markets worldwide while promoting sustainability, fair trade practices, and innovation in agriculture.
                 </p>
                </div>
              </div>
            </div>
            <div class="col-xl-4 col-lg-4 col-md-6 mb-30">
              <div class="footer-widget">
                <div class="footer-widget-heading" >
                  <h3>Useful Links</h3>
                </div>

                <ul className="service-link" style={{width:"70%"}}>
                  <li className="">
                    <Link
                      className="nav-link nab"
                      to="/"
                      style={{ color: "white" }}
                    >
                      &gt; Home
                    </Link>
                  </li>
                  <li className="">
                    <Link
                      className="nav-link nab"
                      to="/About"
                      style={{ color: "white" }}
                    >
                      &gt; About Us
                    </Link>
                  </li>
                  <li className="">
                    <Link
                      className="nav-link nab"
                      to="/product"
                      style={{ color: "white" }}
                    >
                      &gt; Product
                    </Link>
                  </li>
                  <li className="">
                    <Link
                      className="nav-link nab"
                      to="/contact"
                      style={{ color: "white" }}
                    >
                      &gt; Contact Us
                    </Link>
                  </li>
                </ul>
                
              </div>
            </div>
            <div class="col-xl-4 col-lg-4 col-md-6 mb-50">
              <div class="footer-widget">
                <div class="footer-social-icon">
                  <div class="footer-widget-heading">
                    <h3>Follow Us</h3>
                  </div>
                  
                  <a href="https://www.google.co.in/">
                    <FontAwesomeIcon
                      icon={faInstagram}
                      className="fab fa-facebook-f facebook-bg"
                      style={{ color: "white" }}
                    />
                  </a>
                  <a href="https://www.google.co.in/">
                    <FontAwesomeIcon
                      icon={faTwitter}
                      className="fab fa-facebook-f facebook-bg"
                      style={{ color: "white" }}
                    />
                  </a>
                  <a href="https://www.google.co.in/">
                    <FontAwesomeIcon
                      icon={faFacebook}
                      className="fab fa-facebook-f facebook-bg"
                      style={{ color: "white" }}
                    />
                  </a>
                </div>
                
              </div>
             
            </div>
            
          </div>
        </div>
      </div>
      
    </footer>
  );
};

export default Footer;
