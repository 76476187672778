import React from "react";
// import Content from "./Content";
// import p1 from "../Images/P1.png";
import "./About.css";
import p2Image from "../Images/p6.jpg";
import p3Image from "../Images/p3.png";
const About = () => {
  return (
    <div className="main-container">
      <section>
        <div className="about">
          <h1>ABOUT US</h1>
          <h4>
            <span style={{ color: "Yellow" }}>Home/</span>About
          </h4>
        </div>
      </section>

      
      
      <div class="main-section">
        <div class="card-container">
          <img src={p2Image} alt="" class="cardImage" />
          <div class="card-text-container">
            <span class="card-span"></span>
            <h1 class="article-title">Why Sudharshana Farms</h1>
           
            <p class="article-descrip" style={{textAlign:"justify"}}>
              {" "}
              Sudarshana Farms is a newly established export company
                    dedicated to providing the freshest and highest quality
                    fruits and vegetables to markets worldwide. With a
                    commitment to excellence and sustainability, we source our
                    produce from the best growing regions globally, ensuring
                    that our customers receive only the finest products. Our
                    passion for fresh produce drives us to deliver exceptional
                    service and build lasting relationships with our clients.
            </p>
          </div>
        </div>
      </div>
      <div class="main-section">
        <div class="card-container">
          <img src={p3Image} alt="" class="cardImage" />
          <div class="card-text-container">
           
            <p class="article-descrip" style={{textAlign:"justify"}}>
              {" "}
              Sudarshana Farms is a dynamic and forward-thinking company
              specializing in the export of fresh fruits and vegetables. Our
              mission is to deliver premium quality produce to markets worldwide
              while promoting sustainability, fair trade practices, and
              innovation in agriculture.
            </p>
          </div>
        </div>
      </div>

    </div>
  );
};

export default About;
