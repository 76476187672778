import React from "react";
import "./Header.css";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";
// import { faEnvelope } from "@fortawesome/free-solid-svg-icons";

import { Link } from 'react-router-dom';
import logoImage from '../Images/logo.png'
// import { useState } from 'react';


// import {
//   faInstagram,
//   faFacebook,
//   faTwitter,
// } from "@fortawesome/free-brands-svg-icons";
// import About from "./About";
const Header = () => {
  
  return (
   
    <nav class="nav main-container">
  <input type="checkbox" id="nav-check"/>
  <div class="nav-header">
    <div class="nav-title">
      <img src={logoImage} alt="" srcset="" />
    </div>
  </div>
  <div class="nav-btn">
    <label for="nav-check">
      <span></span>
      <span></span>
      <span></span>
    </label>
  </div>
  
  <ul class="nav-list">

       <li className="nav-item">
         <Link className="nav-link" to="/" style={{ color: "white" }}>Home</Link>
       </li>
       <li className="nav-item">
         <Link className="nav-link" to="/About" style={{ color: "white" }}>About Us</Link>
   </li>
   <li className="nav-item">
         <Link className="nav-link" to="/product" style={{ color: "white" }}>Product</Link>
       </li>
       <li className="nav-item">
         <Link className="nav-link" to="/contact" style={{ color: "white" }}>Contact Us</Link>
       </li>
    
  </ul>
</nav>
  );
};

export default Header;
