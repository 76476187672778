import "./App.css";
import Content from "./Component/Content";
import Footer from "./Component/Footer";
import Header from "./Component/Header";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import About from "./Component/About";
import Product from "./Component/Product";
import Contact from "./Component/Contact";



function App() {
  return (
    <Router>
      <div>
        <Header />
        <Routes>
          <Route path="/" element={<Content />} />
          <Route path="/about" element={<About />} />
          <Route path="/product" element={<Product/>} />
          <Route path="/contact" element={<Contact/>} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;

