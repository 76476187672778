import React from "react";
import "./Contact.css";
import { FiPhoneCall } from "react-icons/fi";
import { IoLocationOutline } from "react-icons/io5";
import { AiOutlineMail } from "react-icons/ai";
import { useRef } from "react";
import emailjs from "@emailjs/browser";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const Contact = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm("service_dgec83v", "template_br1t0qi", form.current, {
        publicKey: "gVVUPw5uskJrJP0kg",
      })
      .then(
        () => {
          console.log("SUCCESS!");
        },
        (error) => {
          console.log("FAILED...", error.text);
        }
      );
    toast.success("Email sent successfully!", {
      position: "top-right",
    });

    form.current.reset();
  };
  return (
    <div className="main-container">
      <section>
        <div className="contact">
          <h1>CONTACT US</h1>
          <h4>
            <span style={{ color: "Yellow" }}>Home/</span>Contact
          </h4>
        </div>
      </section>
      <section class="xs-contact-infomation xs-contact-info-1">
        <div class="container social-c">
          <div class="row">
            <div class="col-lg-4 col-md-6">
              <div class="contact-info-group">
                <FiPhoneCall
                  style={{
                    height: "50px",
                    width: "auto",
                    color: "green",
                    margin: "20px 20px",
                  }}
                />
                <h4>Call Numbers</h4>
                <span>Phone: +91 77748 42542</span>
                {/* <span>Tell: +255 714 515 164</span> */}
              </div>
            </div>

            <div class="col-lg-4 col-md-6">
              <div class="contact-info-group">
                <IoLocationOutline
                  style={{
                    height: "50px",
                    width: "auto",
                    color: "green",
                    margin: "20px 20px",
                  }}
                />
                <h4>Physical Address</h4>
                <span>Hno. 159/A , Shinde Vasti , Adgaon , Nashik</span>
              </div>
            </div>

            {/* <div class="col-lg-4 col-md-6">
              <div class="contact-info-group">
                <AiOutlineMail
                  style={{
                    height: "50px",
                    width: "auto",
                    color: "green",
                    margin: "20px 20px",
                  }}
                />
                <h4>Mail Us</h4>
                
                <a class="text-color" href="mailto:info@sudarshanafarms.com">
                  info@sudarshanafarms.com
                </a>
              </div>
            </div> */}
            <div class="col-lg-4 col-md-6">
  <div class="contact-info-group">
    <AiOutlineMail
      style={{
        height: "50px",
        width: "auto",
        color: "green",
        margin: "20px 20px",
      }}
    />
    <h4>Mail Us</h4>
    <a class="text-color" href="mailto:info@sudarshanafarms.com">
      info@sudarshanafarms.com
    </a>
  </div>
</div>

            {/* <div class="col-lg-4 col-md-6">
            <div class="contact-info-group" style={{textAlign:"justify"}}>
            <p>Sudarshana Farms looks forward to serving you with the freshest and finest quality fruits and vegetables, delivered with passion, integrity, and excellence.</p>

                </div>
            </div> */}
          </div>
        </div>
      </section>

      <section className="form-card">
        <form ref={form} onSubmit={sendEmail}>
          <label htmlFor=""></label>
          <label>Name</label>
          <input type="text" name="from_name" />
          <label>Email</label>
          <input type="email" name="from_email" />
          <label>Message</label>
          <textarea name="message" />
          <input type="submit" value="Send" />
        </form>
        <ToastContainer />
      </section>
      <div class="col-lg-12 col-md-12">
            <div class="contactinfo">
            <p>Sudarshana Farms looks forward to serving you with the freshest and finest quality fruits and vegetables, delivered with passion, integrity, and excellence.</p>

                </div>
            </div>
    </div>
  );
};

export default Contact;
